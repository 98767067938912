import { __decorate } from "tslib";
import Vue from 'vue';
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
let ApproveDialog = class ApproveDialog extends Vue {
    get visible() {
        return this.value;
    }
    set visible(value) {
        this.$emit("input", value);
    }
    onOk() {
        this.visible = false;
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], ApproveDialog.prototype, "value", void 0);
__decorate([
    Prop({ required: true, type: String })
], ApproveDialog.prototype, "text", void 0);
__decorate([
    Prop({ default: "Нет", type: String })
], ApproveDialog.prototype, "cancelText", void 0);
__decorate([
    Prop({ default: "Да", type: String })
], ApproveDialog.prototype, "okText", void 0);
__decorate([
    Emit("ok")
], ApproveDialog.prototype, "onOk", null);
ApproveDialog = __decorate([
    Component
], ApproveDialog);
export default ApproveDialog;
